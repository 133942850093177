import React from 'react'
import {Script} from 'gatsby';
import { useGatsbyEnv } from "../../hooks/use-gatsby-env";

export default function MarketingTrackers() {
  const env = useGatsbyEnv();
  if(env !== 'production') {
    return null;
  }

    return (
        <>
            <Script
              id="hubspot-trackers"
                dangerouslySetInnerHTML={{
                    __html: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1186345,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
            />

            <Script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src={`//js.hs-scripts.com/7854587.js`}
            />
        </>
    )
}