import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { GTMHead } from './layout/GoogleTags';
import MarketingTrackers from "./layout/MarketingTracker";

export const Seo = ({title, description, canonical, pageImage, children}) => {
  const {
    title: defaultTitle, 
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    favi,
    favi180,
    favi192,
    favi270,
    author,
    duration
  } = useSiteMetadata();
  const seoImage = pageImage || `${siteUrl}${image}`;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: seoImage,
    url: `${siteUrl}${canonical || ``}`,
    twitterUsername
  }

  return (
    <>
      <link rel="canonical" href={`${siteUrl}${canonical}`} />
      <link rel="shortlink" href={`${siteUrl}${canonical}`} />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta property="twitter:card" content="summary_large_image" />

      {/* Author */}
      <meta data-rh="true" name="twitter:label1" content="Written by" />
      <meta data-rh="true" name="twitter:data1" content={author} />

      {/* Reading time */}
      <meta data-rh="true" name="twitter:label2" content="Reading time" />
      <meta data-rh="true" name="twitter:data2" content={`${duration} min read`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <link rel="icon" href={favi} />
      <link rel="icon"
        href={favi192}
        sizes="192x192" />
      <link rel="apple-touch-icon-precomposed"
            href={favi180} />
      <meta name="msapplication-TileImage"
        content={favi270}
      />
      <link rel="alternate" href="https://sourci.com.au" hrefLang="en-au" />
      <link rel="alternate" href="https://sourci.com" hrefLang="en-us" />
      <link rel="alternate" href="https://sourci.co.nz" hrefLang="en-nz" />
      {children}
      <GTMHead />
      <MarketingTrackers />
      <meta name="facebook-domain-verification"
        content="04lb9x2tjreudqcqk859q9dlb8io3l" />
    </>
  )
}