import { StaticImage } from "gatsby-plugin-image";
import React, {useRef, useEffect, createRef} from "react";
import ReactPlayer from "react-player";
import { Seo } from "../../components/Seo";

const VushLanding = () => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  return (
    <main>
      <section className="py-24">
        <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-center gap-x-12 gap-y-12">
          <div className="flex h-full">
            <StaticImage src="../../images/Sourci_ 455-975w.jpg" 
              alt=""
              placeholder="blurred"
              className="rounded-3xl" />
          </div>
          <div>
            <p className="font-black text-3xl lg:text-4xl">
              Started just over 4 years ago in 2017 by founders Giovanni Pino and Ellie Vaisman, Sourci imported over $100m of consumer goods in the last 2 years alone. Here's how we worked with Vush to help develop their first custom sex toy range and take the brand from strength to strength.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Vush Stimulation.<br />
              Inspired locally.<br />
              Manufactured in China.<br />
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Vush Stimulation is a rapidly growing Australian E-Commerce brand that started in 2018, and saw incredible success thanks to some great marketing, and the self-love wave that has thrived over the past few years. Vush engaged Sourci to assist in developing their new and innovative range of vibrators that would take the self love world by storm.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Fast-forward to 2021, with Sourci's international expertise, a manufacturer was chosen that enabled the development of our initial highly successful range of Vush's bespoke designs, and accomplished an industry blasting defect rate of {`<`}1%, and even 24-month manufacturer warranties - unheard of? We know. Vush has complete control & visibility over its supply chain.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Vush.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/vush-gif2-logo.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>4</span>
            </p>
            <p className="font-black text-xl">Product Range</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>400+</span>
            </p>
            <p className="font-black text-xl">HOURS OF WORK</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>{`<`}1%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8593;24M</span>
            </p>
            <p className="font-black text-xl">WARRANTY</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            Industry leading defect rate
            </h3>
            <svg width="270" height="135" viewBox="0 0 179 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-8"><path d="M1 0V73H179" stroke="black"></path><rect x="11" y="14.599999999999996" width="64" height="58.400000000000006" fill="black"></rect><rect x="109" width="64" fill="#F27BB7" y="43.8" height="29.200000000000003" stroke="black"></rect><text x="140" y="68" fill="black" font-weight="900" font-size="22px" text-anchor="middle" stroke="black">&gt;1%</text><text x="43" y="68" fill="#F27BB7" font-weight="900" font-size="22px" text-anchor="middle">3.4%</text><text x="43" y="87" fill="black" font-weight="bold" font-size="11px" text-anchor="middle">ALONE</text><text x="140" y="87" fill="#F27BB7" font-weight="bold" font-size="11px" text-anchor="middle">WITH SOURCI</text></svg>
            <p className="text-xl lg:text-2xl mb-8">
              There is never going to be a situation where there are zero defects in your manufacturing. Human beings, machines, and human-designed systems are never precision perfect. What's important is working with the most suitable manufacturing partners for your brand, taking into account size, scale, turnaround times, machinery, service level, and after sales service. We can confidently say that Vush’s entire new range of dazzling toys are produced at the best manufacturing facility in the world, suited to their needs.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              <b>Relationship</b> - Native speaking Sourci team members delight & build relationships with factory merchandisers.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              <b>Contracts</b> - Industry leading contractual terms, for a mutually respectful, highly professional business relationship with suppliers.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              <b>Audits & QC Inspections</b> - We frequently and randomly perform independent inspections on factories and finished goods for ultimate consistency of supply.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              <b>Speed</b> - Cutting standard product development time in half, with a team of 50+ dedicated product specialists.
            </p>
            <p className="font-bold text-xl lg:text-2xl">We heart control.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/vush-vibrators2-logo-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            How our sourcing wizardry improved speed-to-market by 6 months+
            </h3>
            <svg width="270" height="135" viewBox="0 0 179 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-8"><path d="M1 0V73H179" stroke="black"></path><rect x="11" y="3.650000000000003" width="64" height="69.35" fill="black"></rect><rect x="109" width="64" fill="#F27BB7" y="29.200000000000003" height="43.8" stroke="black"></rect><text x="140" y="68" fill="black" font-weight="900" font-size="22px" text-anchor="middle" stroke="black">6M</text><text x="43" y="68" fill="#F27BB7" font-weight="900" font-size="22px" text-anchor="middle">18M</text><text x="43" y="87" fill="black" font-weight="bold" font-size="11px" text-anchor="middle">ALONE</text><text x="140" y="87" fill="#F27BB7" font-weight="bold" font-size="11px" text-anchor="middle">WITH SOURCI</text></svg>
            <p className="text-xl lg:text-2xl mb-8">
              With issues in communication, time zones, and of course the right experience we can regularly find a disconnect between an Australian scale-up brand, and their manufacturing partners overseas. Sourci bridges that gap - and makes you feel like your manufacturer is right here.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Our Melbourne team sat down with the team at Vush, conducting regular in-person meetings, reviewing and refining prototypes & iterations, to bring entirely bespoke, fully customised range of adult toys to life at half of the time that it takes most brands.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              No language barriers, and a truly global partner creating magic by your side.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Product development can take anywhere from 12-24 months when you don't have teams dedicated to it across 9 countries, and the end result is rarely half as ideal as originally envisioned. This new dream range ticks all of their boxes, with:
              <ul className="list-disc list-inside ml-4 mt-4">
                <li>Medical Grade Silicone (Highest level available)</li>
                <li>Unique customised shape / form</li>
                <li>Custom vibration sequences (50 options)</li>
                <li>24 Month Manufacturer Warranties</li>
              </ul>
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We also improved efficiencies with custom packaging suppliers, fully integrated their stock with our logistics partners, negotiated competitive pricing.
            </p>
            <p className="font-bold text-xl lg:text-2xl">You're in charge here.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/vush-long-mobile-logo2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="py-24 bg-[#ed76bc]">
        <div className="xl:container mx-auto px-6">
          <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-40 gap-x-60 w-full justify-center items-center">
            <div>
              <div className="mx-auto flex overflow-hidden rounded-3xl relative pt-[56.25%]">
                <ReactPlayer url="https://www.youtube.com/watch?v=hsm4poTWjMs"
                  controls
                  className="absolute top-0 left-0"
                  width="100%"
                  height="100%"
                  config={{
                    youtube: {
                        playerVars: {
                            modestbranding: 1,
                        },
                    },
                  }} />
              </div>
            </div>
            <div>
              <p className="text-white text-2xl lg:text-3xl font-bold">
              Cardi B featured Vush's new Majesty in her music video clip, so it's clearly something special to 'wap' about.<br />
              <a href="https://www.youtube.com/watch?v=hsm4poTWjMs"
                className="underline">Check it out here</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

    </main>
  )
}

export default VushLanding;

export function Head() {
  return (
    <Seo title="Vush Stimulation | Sourci"
      description="With Sourci's international expertise, a manufacturer was chosen that enabled the development of our initial highly successful range of Vush's bespoke designs"
      canonical="/lp/Vush" />
  )
}